import React from 'react';

// BenefitCard Component
const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-center">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

// UseCaseCard Component
const UseCaseCard = ({ title, description }) => (
  <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-left">
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const Strategy = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-teal-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Crafting Your Digital Strategy</h1>
          <p className="text-xl max-w-3xl mx-auto">
            Data-driven strategies are key to achieving ROI and sustainable growth. At <span className="font-bold">TheInvictus</span>, we help businesses develop tailored strategies to align with their objectives.
          </p>
        </div>
      </div>

      {/* Benefits Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why a Robust Strategy Matters</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BenefitCard
              icon="📊"
              title="Data-Driven Decisions"
              description="Leverage analytics to guide key decisions and maximize ROI."
            />
            <BenefitCard
              icon="🔧"
              title="Tailored Approach"
              description="Develop strategies aligned with your business goals and industry."
            />
            <BenefitCard
              icon="📉"
              title="Risk Mitigation"
              description="Identify and address risks early with informed planning."
            />
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Examples of Strategic Success</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <UseCaseCard
              title="Cost Optimization"
              description="Develop financial models to identify cost-saving opportunities."
            />
            <UseCaseCard
              title="Market Expansion"
              description="Create go-to-market strategies tailored to new regions."
            />
            <UseCaseCard
              title="AI Integration"
              description="Plan and implement AI solutions to automate workflows."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-green-500 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Build Your Digital Strategy?</h2>
        <p className="text-lg mb-6">
          Partner with us to craft strategies that deliver measurable success.
        </p>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200"
        >
          Get in Touch
        </a>
      </section>
    </div>
  );
};

export default Strategy;
