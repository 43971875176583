// src/pages/Whitepapers.js
import React, { useState } from 'react';

const Whitepapers = () => {
  const [selectedWhitepaper, setSelectedWhitepaper] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleDownload = (whitepaperLink) => {
    setSelectedWhitepaper(whitepaperLink);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedWhitepaper(null);
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Whitepapers</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Explore our in-depth resources and insights on cloud solutions, AI, and digital transformation.</p>
        </div>
      </div>

      {/* Whitepapers Grid Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Our Whitepapers</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <WhitepaperCard
              title="Optimizing Cloud Costs"
              description="Learn strategies to maximize savings and efficiency in cloud environments."
              onDownload={() => handleDownload('/whitepapers/Optimizing_Cloud_Costs.docx')}
            />
            <WhitepaperCard
              title="AI in Cybersecurity"
              description="How artificial intelligence is transforming cloud security."
              onDownload={() => handleDownload('/whitepapers/AI_in_Cybersecurity.pdf')}
            />
            <WhitepaperCard
              title="Multi-Cloud Management"
              description="A guide to managing multi-cloud environments efficiently."
              onDownload={() => handleDownload('/whitepapers/Multi_Cloud_Management.pdf')}
            />
            <WhitepaperCard
              title="Data Privacy in the Cloud"
              description="Understand best practices for securing data in cloud platforms."
              onDownload={() => handleDownload('/whitepapers/Data_Privacy_in_Cloud.pdf')}
            />
            <WhitepaperCard
              title="Future of Cloud Automation"
              description="Explore trends in automation for cloud infrastructure management."
              onDownload={() => handleDownload('/whitepapers/Future_of_Cloud_Automation.pdf')}
            />
            <WhitepaperCard
              title="FinOps for Cloud Financial Management"
              description="Effective financial management practices for cloud environments."
              onDownload={() => handleDownload('/whitepapers/FinOps_for_Cloud_Financial_Management.pdf')}
            />
          </div>
        </div>
      </section>

      {/* Download Form Modal */}
      {showModal && (
        <LeadCaptureModal
          whitepaperLink={selectedWhitepaper}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

// WhitepaperCard Component for each whitepaper
const WhitepaperCard = ({ title, description, onDownload }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-left">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-gray-700 mb-4">{description}</p>
    <button
      onClick={onDownload}
      className="bg-blue-600 text-white px-4 py-2 rounded-full font-semibold hover:bg-blue-700 transition duration-200 inline-block"
    >
      Download
    </button>
  </div>
);

// Lead Capture Modal Component
const LeadCaptureModal = ({ whitepaperLink, onClose }) => {
  const [formData, setFormData] = useState({ name: '', email: '', company: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Optional: Send formData to backend for lead capture here

    // Trigger download
    const link = document.createElement('a');
    link.href = whitepaperLink;
    link.download = whitepaperLink.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Close modal
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-gray-600">&times;</button>
        <h2 className="text-2xl font-bold mb-4">Download Whitepaper</h2>
        <p className="text-gray-700 mb-6">Please fill in the form below to access your download.</p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            required
          />
          <input
            type="text"
            name="company"
            placeholder="Your Company"
            value={formData.company}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            required
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-full font-semibold hover:bg-blue-700 transition duration-200"
          >
            Download
          </button>
        </form>
      </div>
    </div>
  );
};

export default Whitepapers;
