import React from 'react';
import { Link } from 'react-router-dom';

const Blogs = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section bg-gradient-to-r from-blue-600 to-purple-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Our Blog</h1>
          <p className="text-xl mb-6">
            Explore our latest articles, insights, and guides on cloud management, AI, and more.
          </p>
        </div>
      </section>

      {/* Blogs Grid Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Latest Articles</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <BlogCard
              title="How to Reduce Cloud Costs"
              description="Discover effective strategies to cut down on cloud expenses without compromising performance."
              link="/blogs/reduce-cloud-costs"
              icon="💰"
              color="from-blue-500 to-purple-600"
            />
            <BlogCard
              title="AI in Cloud Security"
              description="Learn how AI is revolutionizing cloud security and helping businesses stay ahead of threats."
              link="/blogs/ai-cloud-security"
              icon="🛡️"
              color="from-green-500 to-teal-600"
            />
            <BlogCard
              title="Multi-Cloud Strategies for Enterprises"
              description="A deep dive into the benefits and challenges of adopting a multi-cloud approach."
              link="/blogs/multi-cloud-strategies"
              icon="☁️"
              color="from-yellow-500 to-orange-600"
            />
            <BlogCard
              title="Cloud Migration Best Practices"
              description="Step-by-step guide on how to successfully migrate your workloads to the cloud."
              link="/blogs/cloud-migration"
              icon="🚀"
              color="from-purple-500 to-pink-600"
            />
            <BlogCard
              title="Understanding FinOps in Cloud Management"
              description="Explore FinOps and how it can optimize your financial operations in the cloud."
              link="/blogs/understanding-finops"
              icon="💼"
              color="from-red-500 to-pink-600"
            />
            <BlogCard
              title="The Future of Cloud Automation"
              description="Get insights into emerging trends and the future of automation in cloud management."
              link="/blogs/future-cloud-automation"
              icon="🤖"
              color="from-indigo-500 to-blue-700"
            />
          </div>
        </div>
      </section>

      {/* Closing CTA Section */}
      <section className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-16 text-center">
        <h2 className="text-3xl font-bold mb-4">Stay Updated with Our Latest Posts</h2>
        <div className="space-x-4">
          <a
            href="/contact"
            className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition"
          >
            Contact Us for Insights
          </a>
        </div>
      </section>
    </div>
  );
};

// BlogCard Component
const BlogCard = ({ title, description, link, icon, color }) => (
  <Link to={link}>
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 text-center relative overflow-hidden">
      <div className={`absolute inset-0 bg-gradient-to-r ${color} opacity-20`}></div>
      <div className="text-5xl mb-4 relative z-10">{icon}</div>
      <h3 className="text-2xl font-bold mb-2 relative z-10">{title}</h3>
      <p className="text-gray-700 mb-4 relative z-10">{description}</p>
      <div className="inline-block bg-blue-600 text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-blue-700 transition relative z-10">
        Read More
      </div>
    </div>
  </Link>
);

export default Blogs;
