import React from 'react';

const AboutUs = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">About TheInvictus</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">
            Simplifying Cloud, Data, and AI for the Modern Business
          </p>
        </div>
      </div>

      {/* Vision, Strategy, and Architecture */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 animate-slide-in">Vision, Strategy, & Architecture</h2>
          <p className="text-lg leading-relaxed max-w-4xl mx-auto mb-12 animate-slide-in">
            At <strong>TheInvictus.ai</strong>, we simplify complex challenges by defining your digital roadmap and delivering outcomes through:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ValuesCard
              icon="🔍"
              title="Vision"
              description="Craft a long-term digital roadmap to align with your business priorities and market trends."
            />
            <ValuesCard
              icon="🔧"
              title="Strategy"
              description="Leverage data insights to drive plans that maximize ROI and operational efficiency."
            />
            <ValuesCard
              icon="🏗️"
              title="Architecture"
              description="Deliver secure, scalable frameworks tailored to modern workloads and enterprise needs."
            />
          </div>
        </div>
      </section>

      {/* Meet the Founder */}
      <section className="py-20 bg-gray-50" id="team">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 animate-slide-in">Meet the Founder</h2>
          <div className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 animate-fade-in">
            <h3 className="text-2xl font-bold mb-2">Ram Papineni</h3>
            <p className="text-blue-600 font-medium mb-4">Founder & CEO</p>
            <p className="text-gray-700 italic">
              "My mission is simple: To help businesses unlock the power of Cloud, Data, and AI through innovative solutions."
            </p>
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-green-500 text-white py-20">
        <h2 className="text-3xl font-bold mb-6">Let’s Transform Together</h2>
        <p className="text-lg mb-8">
          Discover how we can simplify your cloud journey and deliver measurable success.
        </p>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200"
        >
          Start Your Cloud Journey Today
        </a>
      </section>
    </div>
  );
};

// ValuesCard Component
const ValuesCard = ({ icon, title, description }) => (
  <div className="p-6 text-center bg-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default AboutUs;
