import React from 'react';

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-8">
      <div className="container mx-auto text-center">
        {/* Footer Links */}
        <p className="text-sm mb-4">
          &copy; 2024 <span className="font-semibold">TheInvictus</span>. All rights reserved.
        </p>
        <nav className="flex justify-center space-x-6">
          <a
            href="/about"
            className="text-gray-200 hover:text-white hover:underline transition duration-200 ease-in-out"
            aria-label="About Us"
          >
            About
          </a>
          <a
            href="/privacy"
            className="text-gray-200 hover:text-white hover:underline transition duration-200 ease-in-out"
            aria-label="Privacy Policy"
          >
            Privacy
          </a>
          <a
            href="/terms"
            className="text-gray-200 hover:text-white hover:underline transition duration-200 ease-in-out"
            aria-label="Terms and Conditions"
          >
            Terms
          </a>
          <a
            href="/contact"
            className="text-gray-200 hover:text-white hover:underline transition duration-200 ease-in-out"
            aria-label="Contact Us"
          >
            Contact
          </a>
        </nav>

        {/* Tagline */}
        <p className="text-xs text-gray-300 mt-4">
          Unlocking your potential through innovative Cloud, Data, and AI solutions.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
