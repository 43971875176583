import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // Mobile menu toggle
  const [insightsDropdownOpen, setInsightsDropdownOpen] = useState(false); // Insights dropdown toggle
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false); // Resources dropdown toggle

  const insightsDropdownRef = useRef(null);
  const resourcesDropdownRef = useRef(null);
  let insightsTimeout;
  let resourcesTimeout;

  // Toggle mobile menu
  const toggleMenu = () => setIsOpen(!isOpen);

  // Insights hover handlers
  const handleMouseEnterInsights = () => {
    clearTimeout(insightsTimeout);
    setInsightsDropdownOpen(true);
  };

  const handleMouseLeaveInsights = () => {
    insightsTimeout = setTimeout(() => setInsightsDropdownOpen(false), 300); // 300ms delay
  };

  // Resources hover handlers
  const handleMouseEnterResources = () => {
    clearTimeout(resourcesTimeout);
    setResourcesDropdownOpen(true);
  };

  const handleMouseLeaveResources = () => {
    resourcesTimeout = setTimeout(() => setResourcesDropdownOpen(false), 300); // 300ms delay
  };

  const closeDropdowns = () => {
    setInsightsDropdownOpen(false);
    setResourcesDropdownOpen(false);
  };

  return (
    <nav className="bg-gradient-to-r from-blue-600 to-purple-600 text-white fixed w-full top-0 z-50 shadow-md">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-2xl font-bold">
          The<span className="text-purple-300">Invictus</span>
        </Link>

        {/* Hamburger Menu Icon for Mobile */}
        <button
          onClick={toggleMenu}
          className="text-2xl sm:hidden focus:outline-none"
          aria-label="Toggle Menu"
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Desktop Navigation Links */}
        <div className="hidden sm:flex space-x-6 items-center">
          <Link to="/" className="hover:underline">Home</Link>
          <Link to="/about" className="hover:underline">About</Link>
          <Link to="/services" className="hover:underline">Services</Link>

          {/* Insights Dropdown */}
          <div
            className="relative group"
            onMouseEnter={handleMouseEnterInsights}
            onMouseLeave={handleMouseLeaveInsights}
            ref={insightsDropdownRef}
          >
            <button className="hover:underline flex items-center">
              Insights <FaChevronDown className="ml-1" />
            </button>
            {insightsDropdownOpen && (
              <div className="absolute left-0 mt-2 bg-white text-gray-800 rounded-lg shadow-lg z-10">
                <Link to="/insights" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  All
                </Link>
                <Link to="/insights/optimizing-cloud-costs" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  Cloud Cost Optimization
                </Link>
                <Link to="/insights/ai-in-cybersecurity" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  AI in Cybersecurity
                </Link>
                <Link to="/insights/multi-cloud-management" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  Multi-Cloud Management
                </Link>
                <Link to="/insights/digital-transformation-strategy" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  Digital Transformation
                </Link>
              </div>
            )}
          </div>

          {/* Resources Dropdown */}
          <div
            className="relative group"
            onMouseEnter={handleMouseEnterResources}
            onMouseLeave={handleMouseLeaveResources}
            ref={resourcesDropdownRef}
          >
            <button className="hover:underline flex items-center">
              Resources <FaChevronDown className="ml-1" />
            </button>
            {resourcesDropdownOpen && (
              <div className="absolute left-0 mt-2 bg-white text-gray-800 rounded-lg shadow-lg z-10">
                <Link to="/blogs" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdowns}>
                  Blogs
                </Link>
              </div>
            )}
          </div>

          <Link to="/contact" className="hover:underline">Contact</Link>
        </div>
      </div>

      {/* Mobile Drawer Menu */}
      <div
        className={`fixed top-0 left-0 h-screen w-full bg-gradient-to-b from-blue-600 to-purple-600 text-white flex flex-col items-center justify-start pt-20 space-y-6 transform transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:hidden`}
        style={{ zIndex: 100 }}
      >
        <Link to="/" className="text-xl font-semibold hover:text-gray-300" onClick={toggleMenu}>
          Home
        </Link>
        <Link to="/about" className="text-xl font-semibold hover:text-gray-300" onClick={toggleMenu}>
          About
        </Link>
        <Link to="/services" className="text-xl font-semibold hover:text-gray-300" onClick={toggleMenu}>
          Services
        </Link>
        <div>
          <button
            onClick={() => setResourcesDropdownOpen(!resourcesDropdownOpen)}
            className="flex items-center justify-between text-xl font-semibold hover:text-gray-300"
          >
            Resources <FaChevronDown className="ml-1" />
          </button>
          {resourcesDropdownOpen && (
            <div className="flex flex-col mt-2 space-y-2">
              <Link to="/blogs" className="text-lg hover:text-gray-300" onClick={toggleMenu}>
                Blogs
              </Link>
            </div>
          )}
        </div>
        <Link to="/contact" className="text-xl font-semibold hover:text-gray-300" onClick={toggleMenu}>
          Contact
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
