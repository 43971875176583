import React from 'react';

const DataIntegration = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 text-center">
        <h1 className="text-5xl font-bold">Data Integration & Insights</h1>
        <p className="text-xl mt-4">
          Unify fragmented data into AI-ready architectures for actionable insights.
        </p>
      </div>

      {/* Details Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8">What We Offer</h2>
          <p className="text-lg leading-relaxed max-w-4xl mx-auto text-center">
            Our Data Integration services enable businesses to consolidate data, ensure governance, and unlock insights to drive agility and innovation.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <FeatureCard
              title="Unified Data Architecture"
              description="Streamline data from multiple sources into a single, AI-ready architecture."
            />
            <FeatureCard
              title="Real-Time Data Insights"
              description="Enable real-time decision-making with advanced analytics and dashboards."
            />
            <FeatureCard
              title="Data Governance"
              description="Ensure compliance and security with enterprise-grade governance frameworks."
            />
            <FeatureCard
              title="AI-Ready Pipelines"
              description="Prepare your data for seamless integration with AI and machine learning tools."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Unlock Data-Driven Insights?</h2>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200"
        >
          Get Started
        </a>
      </section>
    </div>
  );
};

// Reusable FeatureCard Component
const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default DataIntegration;
