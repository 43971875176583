// src/pages/Testimonials.js
import React from 'react';
import { Link } from 'react-router-dom';

const Testimonials = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">What Our Clients Say</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">Hear from organizations that have achieved cloud optimization, security, and operational excellence with Invictus.</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto py-16">
        {/* Client Testimonials Section */}
        <section className="client-testimonials mb-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <TestimonialCard
              text="Invictus helped us save over 25% on cloud costs, which allowed us to reallocate resources towards new growth initiatives. Their expertise and dedication made a huge difference."
              name="John Doe"
              position="CFO"
              company="Global Manufacturing Company"
            />
            <TestimonialCard
              text="With Invictus’s Security Shield, we were able to maintain continuous HIPAA compliance with ease. Their proactive monitoring has reduced our security risks by 40%."
              name="Jane Smith"
              position="CTO"
              company="Healthcare Provider"
            />
            <TestimonialCard
              text="Since partnering with Invictus, our cloud infrastructure has achieved higher uptime and reliability. Their Operational Excellence solution has been a game-changer for our e-commerce platform."
              name="Alex Lee"
              position="VP of Operations"
              company="E-commerce Company"
            />
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="cta-section text-center bg-blue-600 text-white py-8">
          <h2 className="text-3xl font-semibold mb-4">Ready to Transform Your Cloud Environment?</h2>
          <div className="flex justify-center space-x-4">
            <Link to="/contact" className="bg-white text-blue-600 px-6 py-3 rounded hover:bg-gray-200">Contact a Cloud Expert</Link>
            <Link to="/contact" className="bg-white text-blue-600 px-6 py-3 rounded hover:bg-gray-200">Request a Free Cloud Assessment</Link>
          </div>
        </section>
      </div>
    </div>
  );
};

// Reusable Testimonial Card Component
const TestimonialCard = ({ text, name, position, company }) => (
  <div className="bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition">
    <p className="mb-4">“{text}”</p>
    <p className="font-bold">{name}, {position}</p>
    <p className="text-sm text-gray-600">{company}</p>
  </div>
);

export default Testimonials;
