import React from 'react';

const GetStarted = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 text-center">
        <h1 className="text-5xl font-bold">Start Your Cloud, Data, and AI Transformation</h1>
        <p className="text-xl mt-4">
          Simplify your journey to innovation with expert-led strategies and solutions tailored for your business.
        </p>
      </div>

      {/* How It Works Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <StepCard
              number="1"
              title="Schedule a Call"
              description="Connect with our experts to discuss your business needs and goals."
            />
            <StepCard
              number="2"
              title="Receive a Tailored Plan"
              description="Get a custom roadmap for your Cloud, Data, and AI transformation."
            />
            <StepCard
              number="3"
              title="Start Your Journey"
              description="Collaborate with us to unlock measurable success for your business."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-white py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Business?</h2>
          <p className="text-lg mb-6">
            Let’s collaborate to unlock the full potential of Cloud, Data, and AI for your business.
          </p>
          <a
            href="/contact"
            className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Get in Touch
          </a>
        </div>
      </section>
    </div>
  );
};

// Reusable StepCard Component
const StepCard = ({ number, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-6xl font-bold text-blue-600 mb-4">{number}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default GetStarted;
