import React from 'react';

const Privacy = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Privacy Policy</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">
            Your privacy matters to us. Learn how <span className="font-bold text-yellow-400">TheInvictus</span> protects your data.
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="bg-gray-100 py-10">
        <div className="container mx-auto px-6 lg:px-20 text-gray-800">
          <h2 className="text-4xl font-bold mb-6 text-blue-600 text-center">Overview</h2>
          <p className="mb-4">
            At <strong>TheInvictus</strong>, we are committed to protecting your privacy and ensuring the security of the data you share with us. This Privacy Policy explains how we collect, use, and protect your information when you use our website and services.
          </p>

          {/* Section 1: Information We Collect */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">1. Information We Collect</h2>
          <p className="mb-4">
            We may collect the following types of information when you interact with our platform:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Personal information such as name, email address, and phone number when you sign up or contact us.</li>
            <li>Usage data including IP address, browser type, and interaction patterns on our website.</li>
            <li>Any information you voluntarily provide, such as feedback, survey responses, or support inquiries.</li>
          </ul>

          {/* Remaining sections... */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">2. How We Use Your Information</h2>
          <p className="mb-4">We use the collected information to:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Provide, operate, and improve our website and services.</li>
            <li>Respond to your inquiries and provide customer support.</li>
            <li>Send updates, newsletters, and promotional content (you can opt-out anytime).</li>
            <li>Analyze website usage to enhance user experience.</li>
          </ul>

          {/* Additional sections remain unchanged */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p>
            <a href="mailto:contact@theinvictus.ai" className="text-blue-600 hover:underline">contact@theinvictus.ai</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
