import React from 'react';

const Services = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Our Services</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">
            Empowering your business with cutting-edge Cloud, Data, and AI solutions.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12">What We Offer</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Existing Services */}
            <ServiceCard
              icon="☁️"
              title="Cloud Transformation"
              description="Optimize costs, enhance scalability, and streamline multi-cloud operations."
              link="/services/cloud-transformation"
            />
            <ServiceCard
              icon="📊"
              title="Data Integration & Insights"
              description="Unify fragmented data into AI-ready architectures for actionable insights."
              link="/services/data-integration"
            />
            <ServiceCard
              icon="🤖"
              title="Generative AI Solutions"
              description="Enhance decision-making and automate workflows with lightweight GenAI technologies."
              link="/services/generative-ai"
            />
            <ServiceCard
              icon="🔒"
              title="Security & Compliance"
              description="Protect your data with real-time monitoring, threat detection, and compliance automation."
              link="/services/security-compliance"
            />
            <ServiceCard
              icon="🚀"
              title="AI-Powered Automation"
              description="Automate repetitive tasks and improve productivity using advanced AI algorithms."
              link="/services/ai-automation"
            />
            <ServiceCard
              icon="🔄"
              title="Migration Services"
              description="Seamlessly migrate your applications and data to the cloud with minimal disruption."
              link="/services/migration"
            />

            {/* New Vision, Strategy, and Architecture Services */}
            <ServiceCard
              icon="🔍"
              title="Vision"
              description="Define your digital North Star with actionable goals for Cloud, Data, and AI."
              link="/services/vision"
            />
            <ServiceCard
              icon="🛠️"
              title="Strategy"
              description="Create data-driven strategies that maximize ROI while aligning with business goals."
              link="/services/strategy"
            />
            <ServiceCard
              icon="🏗️"
              title="Architecture"
              description="Design scalable and secure architectures tailored for modern workloads."
              link="/services/architecture"
            />
          </div>
        </div>
      </section>

      {/* Why Choose Invictus Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why Choose TheInvictus?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BenefitCard
              icon="⚡"
              title="Innovation at Scale"
              description="From GenAI to real-time data pipelines, we bring the future of technology to your business."
            />
            <BenefitCard
              icon="🔒"
              title="Secure by Design"
              description="Our solutions prioritize security and compliance, ensuring your data and operations remain protected."
            />
            <BenefitCard
              icon="📈"
              title="Results You Can Measure"
              description="We deliver solutions designed to optimize costs, enhance performance, and drive measurable outcomes."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-green-500 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">Ready to Drive Innovation?</h2>
        <p className="text-lg mb-6">
          Let’s collaborate to unlock the full potential of Cloud, Data, and AI for your business.
        </p>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200"
        >
          Get in Touch
        </a>
      </section>
    </div>
  );
};

// ServiceCard Component for each service
const ServiceCard = ({ icon, title, description, link }) => (
  <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
    <a href={link} className="text-blue-500 hover:underline mt-4 inline-block">
      Learn More
    </a>
  </div>
);

// BenefitCard Component for key benefits
const BenefitCard = ({ icon, title, description }) => (
  <div className="p-4 text-center bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default Services;
