import React from 'react';

function Contact() {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Contact Us</h1>
          <p className="text-lg max-w-2xl mx-auto whitespace-nowrap">
            Have questions or need assistance? We’re here to help. Reach out to our team today.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        {/* Contact Info Section */}
        <section className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <ContactCard
            icon="✉️"
            title="Email Support"
            description={
              <>
                Reach us at{' '}
                <a
                  href="mailto:contact@theinvictus.ai"
                  className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
                >
                  contact@theinvictus.ai
                </a>
                .
              </>
            }
          />
          <ContactCard
            icon="📞"
            title="Call Us"
            description={
              <>
                Call us at{' '}
                <a
                  href="tel:+13485583"
                  className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
                >
                  +1 (469) 348-5583
                </a>
                .
              </>
            }
          />
          <ContactCard
            icon="📍"
            title="Visit Us"
            description="TheInvictus HQ, 10332 Offshore Dr, Irving, TX 75063"
          />
        </section>

        {/* Support Hours Section */}
        <section className="mt-12">
          <h2 className="text-2xl font-bold mb-4 text-blue-600 text-center">Support Hours</h2>
          <p className="text-lg text-gray-700 text-center">
            <span className="font-semibold">Monday - Friday:</span> 9 AM - 5 PM (CST)
          </p>
        </section>

        {/* Feedback Section */}
        <section className="mt-16 text-center">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">We Value Your Feedback</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            Your feedback helps us improve TheInvictus. Share your thoughts at{' '}
            <a
              href="mailto:contact@theinvictus.ai"
              className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
            >
              contact@theinvictus.ai
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
}

// Reusable Contact Card Component
const ContactCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-center">
    <div className="flex justify-center items-center text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default Contact;
