import React from 'react';

// BenefitCard Component
const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-center">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

// UseCaseCard Component
const UseCaseCard = ({ title, description }) => (
  <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-left">
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const Architecture = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-orange-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Designing Scalable Architectures</h1>
          <p className="text-xl max-w-3xl mx-auto">
            Future-proof your business with scalable, secure, and tailored architectures. At <span className="font-bold">TheInvictus</span>, we specialize in building systems designed for modern workloads.
          </p>
        </div>
      </div>

      {/* Benefits Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why Modern Architecture Matters</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BenefitCard
              icon="🛠️"
              title="Scalable Solutions"
              description="Build systems that scale with your growing needs."
            />
            <BenefitCard
              icon="🔒"
              title="Secure by Design"
              description="Ensure compliance and data security with industry best practices."
            />
            <BenefitCard
              icon="📐"
              title="Tailored to You"
              description="Design architectures that align with your unique requirements."
            />
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">How Modern Architectures Transform Businesses</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <UseCaseCard
              title="Cloud-Native Applications"
              description="Leverage microservices and containerization for better agility."
            />
            <UseCaseCard
              title="Data-Driven Workloads"
              description="Architect data pipelines optimized for real-time insights."
            />
            <UseCaseCard
              title="AI-Ready Systems"
              description="Prepare your infrastructure for AI-driven workloads."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-green-500 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Design Scalable Architectures?</h2>
        <p className="text-lg mb-6">
          Let’s create architectures that drive innovation and efficiency for your business.
        </p>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200"
        >
          Get in Touch
        </a>
      </section>
    </div>
  );
};

export default Architecture;
