import React from 'react';

const CloudTransformation = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 text-center">
        <h1 className="text-5xl font-bold">Cloud Transformation</h1>
        <p className="text-xl mt-4">
          Optimize costs, enhance scalability, and streamline multi-cloud operations.
        </p>
      </div>

      {/* Details Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8">What We Offer</h2>
          <p className="text-lg leading-relaxed max-w-4xl mx-auto text-center">
            Our Cloud Transformation services help you navigate the complexities of cloud adoption,
            from strategy to implementation, ensuring cost-efficiency and operational excellence.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <FeatureCard
              title="Cost Optimization"
              description="Real-time cost analysis and automated recommendations to save up to 30% on cloud expenses."
            />
            <FeatureCard
              title="Multi-Cloud Strategy"
              description="Leverage the best of multiple cloud providers to enhance performance and security."
            />
            <FeatureCard
              title="Proactive Monitoring"
              description="24/7 monitoring to ensure high availability and performance for your cloud infrastructure."
            />
            <FeatureCard
              title="Seamless Migration"
              description="Migrate workloads to the cloud with minimal disruption and maximum ROI."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Cloud?</h2>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200"
        >
          Get Started
        </a>
      </section>
    </div>
  );
};

// Reusable FeatureCard Component
const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default CloudTransformation;
