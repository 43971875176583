import React from 'react';
import BackButton from '../../components/BackButton';

const DigitalTransformation = () => {
  return (
    <div className="container mx-auto py-16">
      <BackButton targetPath="/insights" />
      {/* Hero Section */}
      <section className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 px-4 rounded-lg mb-16 fade-in">
        <div className="text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Digital Transformation Strategy</h1>
          <p className="text-lg max-w-2xl mx-auto animate-fade-in delay-1s">
            Discover how to drive digital innovation and build a resilient, future-ready business.
          </p>
        </div>
      </section>

      {/* Introduction */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800 text-center">Introduction to Digital Transformation</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          Digital transformation is more than just technology adoption. It's about rethinking how an organization operates and delivers value to its customers. A strategic approach to digital transformation can help businesses become more agile, efficient, and customer-focused.
        </p>
      </section>

      {/* Key Components */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800 text-center">Key Components of Digital Transformation</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ComponentCard
            icon="🖥️"
            title="Technology Integration"
            description="Adopt cutting-edge technologies that streamline operations and enhance customer experiences."
          />
          <ComponentCard
            icon="🔄"
            title="Process Innovation"
            description="Redefine workflows and processes to increase efficiency and adaptability."
          />
          <ComponentCard
            icon="👥"
            title="Cultural Transformation"
            description="Foster a culture that embraces change, innovation, and continuous learning."
          />
        </div>
      </section>

      {/* Strategies for Success */}
      <section className="px-4 mb-16">
        <h2 className="text-4xl font-bold mb-4 text-gray-800 text-center">Strategies for a Successful Digital Transformation</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <StrategyCard
            icon="🎯"
            title="Define Clear Goals"
            description="Establish a clear vision and measurable goals to guide your transformation journey."
          />
          <StrategyCard
            icon="📊"
            title="Leverage Data Analytics"
            description="Use data-driven insights to make informed decisions and track progress."
          />
          <StrategyCard
            icon="👩‍💻"
            title="Empower Your Workforce"
            description="Invest in training and development to build a tech-savvy and adaptable workforce."
          />
        </div>
      </section>

      {/* Conclusion & CTA */}
      <section className="cta-section text-center bg-blue-600 text-white py-16 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4 text-center">Accelerate Your Digital Transformation with Invictus</h2>
        <p className="text-lg mb-6">Reach out to our team to create a tailored digital transformation strategy that drives growth and resilience.</p>
        <div className="space-x-4">
          <a href="/services" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Explore Our Services</a>
          <a href="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">Contact Us</a>
        </div>
      </section>
    </div>
  );
};

// Reusable Component Card
const ComponentCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

// Reusable Strategy Card
const StrategyCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default DigitalTransformation;
