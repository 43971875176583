import React from 'react';

const SecurityCompliance = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-blue-800 text-white py-20 text-center">
        <h1 className="text-5xl font-bold">Security & Compliance</h1>
        <p className="text-xl mt-4">
          Protect your data with real-time monitoring, threat detection, and compliance automation.
        </p>
      </div>

      {/* Details Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8">What We Offer</h2>
          <p className="text-lg leading-relaxed max-w-4xl mx-auto text-center">
            Our Security & Compliance solutions ensure your business is safeguarded from threats while maintaining regulatory compliance.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <FeatureCard
              title="Real-Time Monitoring"
              description="Track vulnerabilities and security threats in real time."
            />
            <FeatureCard
              title="Compliance Automation"
              description="Automate compliance processes to meet industry standards like HIPAA, GDPR, and SOC2."
            />
            <FeatureCard
              title="Threat Detection"
              description="Identify and respond to threats with AI-powered detection systems."
            />
            <FeatureCard
              title="Incident Response"
              description="Mitigate risks with a robust incident response plan tailored to your business."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-blue-600 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Secure Your Business?</h2>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transform hover:scale-105 transition duration-200"
        >
          Get Started
        </a>
      </section>
    </div>
  );
};

const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default SecurityCompliance;
