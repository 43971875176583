// src/components/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ targetPath = '/' }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(targetPath);
  };

  return (
    <button
      onClick={goBack}
      className="absolute top-20 left-6 p-2 px-4 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 transition flex items-center space-x-1 text-sm"
      style={{ zIndex: 1000 }}
    >
      <i className="fas fa-arrow-left"></i>
      {/* <span>Back</span> */}
    </button>
  );
};

export default BackButton;
