import React from 'react';

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20 mt-0 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-6 animate-fade-in">
            Transforming Businesses with Cloud, Data, and AI
          </h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">
            At <span className="font-bold text-yellow-400">TheInvictus</span>, we empower organizations to unlock their full potential through innovative, scalable, and impactful solutions.
          </p>
          <a
            href="/get-started"
            className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200 animate-fade-in delay-2s"
          >
            Get Started
          </a>
        </div>
      </section>

      {/* VSA Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Our Approach</h2>
          <p className="text-lg leading-relaxed max-w-4xl mx-auto mb-12">
            Driving innovation with a clear Vision, data-driven Strategy, and robust Architecture.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ValuesCard
              icon="🔍"
              title="Vision"
              description="Set actionable goals to guide your Cloud, Data, and AI journey."
            />
            <ValuesCard
              icon="🔧"
              title="Strategy"
              description="Develop effective plans that maximize value and align with objectives."
            />
            <ValuesCard
              icon="🏗️"
              title="Architecture"
              description="Build flexible, secure, and scalable systems to support your goals."
            />
          </div>
        </div>
      </section>

      {/* What We Do Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12">What We Do</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
              <div className="text-blue-600 text-5xl mb-4">☁️</div>
              <h3 className="text-2xl font-bold mb-2">Cloud Transformation</h3>
              <p className="text-gray-700">
                Reduce costs, enhance scalability, and streamline multi-cloud operations.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
              <div className="text-blue-600 text-5xl mb-4">📊</div>
              <h3 className="text-2xl font-bold mb-2">Data Integration & Insights</h3>
              <p className="text-gray-700">
                Unify fragmented data into AI-ready architectures for actionable insights.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
              <div className="text-blue-600 text-5xl mb-4">🤖</div>
              <h3 className="text-2xl font-bold mb-2">AI-Powered Solutions</h3>
              <p className="text-gray-700">
                Integrate lightweight GenAI technologies to automate workflows and enhance decision-making.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Inspiration Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16">
        <h2 className="text-3xl font-semibold mb-4">
          Ready to Unlock the Power of Cloud, Data, and AI?
        </h2>
        <p className="text-lg mb-6">
          Simplifying the complexities of the digital age with innovative, scalable solutions.
        </p>
        <div className="space-x-4">
          <a
            href="/get-started"
            className="bg-yellow-500 text-white px-6 py-3 rounded font-semibold hover:bg-yellow-600 transform hover:scale-105 transition duration-200"
          >
            Get Started
          </a>
          <a
            href="/contact"
            className="bg-white text-blue-600 px-6 py-3 rounded font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200"
          >
            Contact Us
          </a>
        </div>
      </section>
    </div>
  );
};

// ValuesCard Component
const ValuesCard = ({ icon, title, description }) => (
  <div className="p-6 text-center bg-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

export default Home;
