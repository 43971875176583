import React from 'react';

const Terms = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section relative bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 fade-in">
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4 animate-fade-in">Terms and Conditions</h1>
          <p className="text-xl mb-6 animate-fade-in delay-1s">
            Welcome to <span className="font-bold text-yellow-400">TheInvictus</span>. Please read our terms carefully before using our services.
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="bg-gray-100 py-10">
        <div className="container mx-auto px-6 lg:px-20 text-gray-800">
          <h2 className="text-4xl font-bold mb-6 text-blue-600 text-center">Overview</h2>
          <p className="mb-4">
            By accessing and using our website and services, you agree to comply with the following terms and conditions. Please read them carefully.
          </p>

          {/* Section 1: General Terms */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">1. General Terms</h2>
          <p className="mb-4">
            By accessing our website, you confirm that you are at least 18 years old or have the consent of a legal guardian. You agree to use our services only for lawful purposes and in accordance with these terms.
          </p>

          {/* Section 2: Intellectual Property */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">2. Intellectual Property</h2>
          <p className="mb-4">
            All content, logos, trademarks, and materials on this website are the property of <strong>TheInvictus</strong> unless otherwise stated. You may not copy, reproduce, distribute, or use any of the content without prior written consent.
          </p>

          {/* Additional sections remain unchanged */}
          <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about these terms, please contact us at:
          </p>
          <p>
            <a href="mailto:contact@theinvictus.ai" className="text-blue-600 hover:underline">contact@theinvictus.ai</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
