import React from 'react';

const Vision = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Defining Your Digital Vision</h1>
          <p className="text-xl max-w-3xl mx-auto">
            A clear vision is the cornerstone of digital success. At <span className="font-bold">TheInvictus</span>,
            we help businesses define actionable goals for Cloud, Data, and AI to drive transformation and growth.
          </p>
        </div>
      </div>

      {/* Benefits Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Why a Strong Vision Matters</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BenefitCard
              icon="🎯"
              title="Actionable Goals"
              description="Define clear, actionable objectives to guide your digital transformation journey."
            />
            <BenefitCard
              icon="📈"
              title="Business Alignment"
              description="Ensure your vision aligns with strategic business goals and priorities."
            />
            <BenefitCard
              icon="🏆"
              title="Competitive Edge"
              description="Stay ahead of the competition by leveraging a forward-thinking digital vision."
            />
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Impact of a Clear Digital Vision</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <UseCaseCard
              title="Accelerating Cloud Adoption"
              description="Develop a clear roadmap to optimize cloud migration and multi-cloud strategies."
            />
            <UseCaseCard
              title="AI-Driven Decision Making"
              description="Leverage AI technologies to make informed, strategic business decisions."
            />
            <UseCaseCard
              title="Streamlined Operations"
              description="Integrate data-driven processes for greater efficiency and cost savings."
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="text-center bg-gradient-to-r from-blue-600 to-green-500 text-white py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Define Your Digital Vision?</h2>
        <p className="text-lg mb-6">
          Let’s work together to create a vision that empowers your business to achieve lasting success.
        </p>
        <a
          href="/contact"
          className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transform hover:scale-105 transition duration-200"
        >
          Get in Touch
        </a>
      </section>
    </div>
  );
};

// Reusable BenefitCard Component
const BenefitCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-center">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

// Reusable UseCaseCard Component
const UseCaseCard = ({ title, description }) => (
  <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 text-left">
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default Vision;
